import produce from "immer";
import { createSelector } from "reselect";
import { createActions, handleActions } from "redux-actions";
import apiClient from "utility/apiClient";

const api = {
  fetchPermessi: function fetchPermessi({ id, itemType }) {
    return apiClient()({
      action: "get_permessi_oggetto",
      data: {
        id_oggetto: id,
        tipo_oggetto: itemType,
      },
    });
  },
};

/* -- actions -- */
export const { togglePermissionsWindow } = createActions({
  TOGGLE_PERMISSIONS_WINDOW: (id, itemType) => ({ id, itemType }),
});

export const {
  fetchPermissionsRequest,
  fetchPermissionsSucceeded,
  fetchPermissionsFailed,
} = createActions({
  FETCH_PERMISSIONS_REQUEST: undefined,
  FETCH_PERMISSIONS_SUCCEEDED: undefined,
  FETCH_PERMISSIONS_FAILED: undefined,
});

export const {
  setPermissionsRequest,
  setPermissionsSucceeded,
  setPermissionsFailed,
} = createActions({
  SET_PERMISSIONS_REQUEST: undefined,
  SET_PERMISSIONS_SUCCEEDED: undefined,
  SET_PERMISSIONS_FAILED: undefined,
});

export const fetchPermissions = ({ id, itemType }) => async dispatch => {
  dispatch(fetchPermissionsRequest());

  try {
    const { permessi } = await api.fetchPermessi({ id, itemType });

    const massagedPermissions = permessi.map(({ permesso, ...user }) => ({
      ...user,
      permission: parseInt(permesso, 10),
    }));

    dispatch(fetchPermissionsSucceeded({ permissions: massagedPermissions }));
  } catch (err) {
    console.log(err);
    dispatch(fetchPermissionsFailed(err));
  }
};

/* -- reducer -- */
export const reducer = handleActions(
  {
    [togglePermissionsWindow]: (state, action) =>
      produce(state, draft => {
        if (!draft.meta.mounted) {
          const { payload } = action;
          const { id, itemType } = payload;
          draft.meta.id = id;
          draft.meta.itemType = itemType;
          draft.meta.loading = true;
        }

        draft.meta.mounted = !draft.meta.mounted;
      }),
    [fetchPermissionsRequest]: (state, action) =>
      produce(state, draft => {
        draft.meta.loading = true;
      }),
    [fetchPermissionsSucceeded]: (state, action) =>
      produce(state, draft => {
        const { payload } = action;
        draft.data.permissions = payload.permissions;
        draft.meta.loading = false;
      }),
    [fetchPermissionsFailed]: (state, action) =>
      produce(state, draft => {
        draft.meta.loading = false;
      }),
    [setPermissionsRequest]: (state, action) =>
      produce(state, draft => {
        draft.meta.submitted = true;
      }),
    [setPermissionsSucceeded]: (state, action) =>
      produce(state, draft => {
        const { payload } = action;
        draft.data.permissions = payload.permissions;
        draft.meta.submitted = false;
      }),
    [setPermissionsFailed]: (state, action) =>
      produce(state, draft => {
        draft.meta.submitted = false;
      }),
  },
  {
    data: {
      permissions: [],
    },
    meta: {
      id: 0,
      itemType: 0,
      visible: false,
      loading: false,
      submitted: false,
    },
  }
);

/* -- reducer -- */
export const permissionsWindowSelector = state => state.permissionsWindow;

export const permissionsWindowDataSelector = createSelector(
  permissionsWindowSelector,
  permissionsWindow => permissionsWindow.data
);

export const permissionsSelector = createSelector(
  permissionsWindowDataSelector,
  permissionsWindowData => permissionsWindowData.permissions
);

export const permissionsWindowMetaSelector = createSelector(
  permissionsWindowSelector,
  permissionsWindow => permissionsWindow.meta
);
