import axios from "axios";

import i18next from "../i18n";

const defaultOptions = {
  url: "index.php",
  useCache: false,
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

function getErrorMessage(err) {
  const language = i18next.language;

  const { error } = err.response.data;

  if (error.message) {
    return error.message[language];
  }

  return error.details || error.code;
}

const client = (passedOptions = {}) => ({ data, action }) => {
  const options = {
    ...defaultOptions,
    ...passedOptions,
  };

  const { store, persistor } = require("store");
  const { userSelector, updateToken } = require("store/user");

  const { url, endpoint } = options;

  const { token, userid } = userSelector(store.getState()) || {};

  const language = i18next.language;

  const formData = new FormData();

  const reqData = {
    ...data,
    token,
    userid,
    user_lang: language,
    clientid: process.env.REACT_APP_CLIENT_ID,
  };

  if (options.help) {
    reqData.help = true;
  }

  for (let key in reqData) {
    if (typeof reqData[key] !== "undefined") {
      formData.append(key, reqData[key]);
    }
  }

  const client = axios.post(
    endpoint || `${process.env.REACT_APP_MS}/${url}?action=${action}`,
    formData,
    {
      headers: {
        ...defaultOptions.headers,
        ...passedOptions.headers,
      },
    }
  );

  console.groupCollapsed(`[apiClient] request ${action}`);
  console.table(data);
  console.groupEnd();

  return client
    .then(x => x.data)
    .then(res => {
      const { token } = res;

      if (token) {
        store.dispatch(updateToken(token));
      }

      return res.data;
    })
    .catch((err, x) => {
      if (err.response.data.error.code === "Wrong token") {
        persistor.purge();
        store.dispatch({ type: "USER_LOGOUT" });
      } else {
        throw new Error(getErrorMessage(err));
      }
    });
};

window.apiClient = client;

export default client;

export const UPLOAD_ACTIONS = {
  MARKER_DATA: "dato",
  VIEW: "vista",
  OBJ: "obj",
};

export const uploader = (
  action,
  { markerId, rilievoId, obj, relativePath } = {}
) => file => {
  return client({ endpoint: "https://files.primis.live" })({
    data: {
      action,
      valore: file,
      id_marker: markerId,
      id_rilievo: rilievoId,
      obj,
      rel_path: relativePath,
    },
  });
};
