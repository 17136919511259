export default [
  {
    id: 1,
    name: "Layer 1",
    objects: [
      {
        color: [255, 0, 0, 255],
        id: 0,
        type: "drawing",
        path: [
          [8.917151257493545, 39.252599281869834],
          [8.917151257493545, 39.25257435779413],
          [8.917151257493545, 39.25239988901605],
          [8.917151257493545, 39.25217557137782],
          [8.9171727151656, 39.25174355020084],
          [8.917215630509913, 39.25157738750092],
          [8.917247817018097, 39.25144445705755],
          [8.917322918870594, 39.2513032181853],
          [8.917376563050807, 39.25121182817528],
          [8.917440936067175, 39.251120438046044],
          [8.917494580247388, 39.25106228062912],
          [8.917548224427831, 39.251012431376196],
          [8.917569682099884, 39.25098750673644],
          [8.917580410936013, 39.25097919852117],
          [8.917601868608068, 39.25097919852117],
          [8.917612597444197, 39.250970890304984],
          [8.917634055116228, 39.250970890304984],
          [8.917644783952381, 39.250970890304984],
          [8.91768769929644, 39.250970890304984],
          [8.91777352998509, 39.25095427386961],
          [8.918181225755276, 39.250813034010136],
          [8.918631836869773, 39.250680102117705],
          [8.919007346131773, 39.25060532781751],
          [8.919554516770821, 39.25053886170602],
          [8.920080229737817, 39.25053055343759],
          [8.92049865434413, 39.25055547823976],
          [8.920841977097972, 39.25063025259311],
          [8.921002909638865, 39.25070502686672],
          [8.921196028687943, 39.25088780808884],
          [8.921196028687943, 39.250945965650466],
          [8.921196028687943, 39.250970890304984],
          [8.921196028687943, 39.25099581495064],
          [8.921196028687943, 39.251012431376196],
          [8.921196028687943, 39.25104566421537],
          [8.921196028687943, 39.251087205242285],
          [8.921196028687943, 39.2511287462445],
          [8.921196028687943, 39.25118690360641],
          [8.921196028687943, 39.25125336910367],
          [8.921196028687943, 39.25134475905963],
          [8.921206757524072, 39.25144445705755],
          [8.921228215196102, 39.25155246306198],
          [8.921249672868385, 39.25166046890014],
          [8.921260401704286, 39.25172693394857],
          [8.92127113054044, 39.25179339893401],
          [8.92127113054044, 39.25184324763179],
          [8.92127113054044, 39.2518930962941],
          [8.92127113054044, 39.251942944920984],
          [8.921249672868385, 39.251984485416315],
          [8.921206757524072, 39.25202602588699],
          [8.921142384507704, 39.25205925824593],
          [8.921045824983178, 39.25210910675465],
          [8.920884892442285, 39.25217557137782],
          [8.920702502229362, 39.25224203593807],
          [8.920520112016185, 39.25229188431687],
          [8.920273348786894, 39.25233342460552],
          [8.91988711068874, 39.25239158096822],
          [8.919618889787165, 39.25243312119779],
          [8.919425770738112, 39.25246635336368],
          [8.919243380525163, 39.2524912774778],
          [8.91909317682045, 39.252499585513846],
          [8.91895370195156, 39.252507893548966],
          [8.91883568475498, 39.25252450961611],
          [8.918728396394298, 39.25252450961611],
          [8.918621108033644, 39.25252450961611],
          [8.918524548509092, 39.25252450961611],
          [8.918449446656851, 39.25252450961611],
          [8.918374344804354, 39.25252450961611],
          [8.918309971787986, 39.25252450961611],
          [8.918245598771618, 39.25251620158305],
          [8.918191954591405, 39.252507893548966],
          [8.918149039247092, 39.252499585513846],
          [8.918116852738908, 39.2524912774778],
          [8.91809539506688, 39.25248296944077],
          [8.918063208558696, 39.25247466140267],
          [8.918041750886411, 39.25246635336368],
          [8.918020293214383, 39.25246635336368],
          [8.917988106706199, 39.25246635336368],
          [8.917934462525984, 39.25246635336368],
          [8.917891547181672, 39.25248296944077],
          [8.917827174165305, 39.252499585513846],
          [8.917762801148937, 39.25251620158305],
          [8.917730614640778, 39.25253281764831],
          [8.917655512788281, 39.25254943370955],
          [8.917601868608068, 39.25257435779413],
          [8.91755895326373, 39.25259097384563],
          [8.917526766755547, 39.252599281869834],
          [8.917494580247388, 39.252599281869834],
          [8.917473122575334, 39.252599281869834],
          [8.917451664903304, 39.252599281869834],
          [8.917440936067175, 39.252599281869834],
          [8.917440936067175, 39.252599281869834],
          [8.917430207231021, 39.252599281869834],
          [8.917430207231021, 39.252599281869834],
          [8.917430207231021, 39.252599281869834],
          [8.91741947839512, 39.252599281869834],
          [8.91741947839512, 39.252599281869834],
          [8.917365834214678, 39.252599281869834],
          [8.917322918870594, 39.252599281869834],
          [8.91729073236241, 39.252599281869834],
          [8.917258545854226, 39.252599281869834],
          [8.917237088181944, 39.252599281869834],
          [8.91718344400173, 39.252599281869834],
          [8.917151257493545, 39.252599281869834],
          [8.917108342149232, 39.252599281869834],
          [8.917097613313333, 39.252599281869834],
          [8.917086884477204, 39.252599281869834],
          [8.917086884477204, 39.25260758989318],
        ],
        author: 11,
      },
      {
        color: [255, 0, 0, 255],
        id: 1,
        type: "drawing",
        path: [[8.915735051133687, 39.25483410464715]],
        author: 11,
      },
      {
        color: [255, 0, 0, 255],
        id: 2,
        type: "drawing",
        path: [
          [8.919208511808781, 39.2521007987225],
          [8.919195100763512, 39.252090413618184],
          [8.919189736345587, 39.25208625957609],
          [8.919189736345587, 39.25208625957609],
          [8.919189736345587, 39.25208418255485],
          [8.919189736345587, 39.25208418255485],
          [8.919189736345587, 39.25208002851241],
          [8.919187054136447, 39.25207172042657],
          [8.919187054136447, 39.252061335318004],
          [8.919187054136447, 39.25204679616332],
          [8.919187054136447, 39.25202810296008],
          [8.919187054136447, 39.25200525570499],
          [8.919203147390578, 39.25196994629593],
          [8.91921924064463, 39.251947099021805],
          [8.919246062734864, 39.25192009768828],
          [8.919262155988994, 39.25190555850436],
          [8.919288978079228, 39.251886865263515],
          [8.919323846796248, 39.25187024904513],
          [8.919366762140585, 39.25185778687881],
          [8.919463321665061, 39.25183493956812],
          [8.919516965845299, 39.25183078551091],
          [8.919600114324835, 39.251826631453326],
          [8.91965375850505, 39.251828708482144],
          [8.919699356058452, 39.251849478766744],
          [8.919731542566634, 39.251866094989914],
          [8.91976909349282, 39.251886865263515],
          [8.919801280000978, 39.251909712557165],
          [8.919825419882072, 39.25193255984353],
          [8.919846877554152, 39.25195748414735],
          [8.919860288599395, 39.25198033141812],
          [8.919865653017345, 39.25200317868133],
          [8.919865653017345, 39.25203848807364],
          [8.919849559763216, 39.252075874469604],
          [8.919814691045993, 39.25212364594656],
          [8.919777140119809, 39.252169340372305],
          [8.919747635820764, 39.25219634160985],
          [8.919718131521416, 39.25222334283712],
          [8.919688627222373, 39.25223788195511],
          [8.919656440714189, 39.25224619002115],
          [8.919626936415069, 39.25225449808632],
          [8.919573292234652, 39.252260729134534],
          [8.919530376890568, 39.25226488316627],
          [8.919490143755294, 39.25226488316627],
          [8.91944991062007, 39.25226488316627],
          [8.919412359693707, 39.252256575102415],
          [8.919382855394614, 39.25225034405388],
          [8.919358715513495, 39.252242035988296],
          [8.919339940050302, 39.25223165090488],
          [8.919323846796248, 39.25222126581993],
          [8.919305071333282, 39.252206726698496],
          [8.919286295870087, 39.252192187574046],
          [8.919267520406894, 39.252177648446484],
          [8.919254109361903, 39.252167263353634],
          [8.919240698316914, 39.25215064720171],
          [8.91923801610785, 39.25214233912435],
          [8.919235333898735, 39.25213195402616],
          [8.919232651689875, 39.25212364594656],
          [8.91922996948081, 39.25211741488621],
          [8.91922996948081, 39.25211326084565],
          [8.91922996948081, 39.2521111838253],
          [8.91922728727167, 39.2521091068048],
          [8.91922728727167, 39.2521091068048],
          [8.91922728727167, 39.25210702978433],
          [8.919224605062835, 39.25210495276383],
          [8.919224605062835, 39.25210495276383],
          [8.919224605062835, 39.252102875743155],
          [8.919224605062835, 39.252102875743155],
          [8.919224605062835, 39.2521007987225],
          [8.91922192285377, 39.2521007987225],
          [8.91922192285377, 39.2520987217018],
          [8.91922192285377, 39.252096644680925],
          [8.91922192285377, 39.252096644680925],
          [8.91922192285377, 39.252096644680925],
          [8.91922192285377, 39.252096644680925],
        ],
        author: 11,
      },
      {
        color: [255, 0, 0, 255],
        id: 3,
        type: "drawing",
        path: [[8.918792769411278, 39.2527280561702]],
        author: 11,
      },
      {
        color: [255, 0, 0, 255],
        id: 4,
        type: "drawing",
        path: [
          [8.918191954566877, 39.25166254592507],
          [8.918181225730773, 39.25166254592507],
          [8.918106123878276, 39.25166254592507],
          [8.918031022026009, 39.25161269709888],
          [8.91802029318988, 39.25157946452842],
          [8.917966649009665, 39.25148807487833],
          [8.917945191337383, 39.251413301439655],
          [8.917945191337383, 39.25133021974769],
          [8.917945191337383, 39.25128037068522],
          [8.917945191337383, 39.25123052158733],
          [8.917945191337383, 39.25119728883566],
          [8.917945191337383, 39.25116405606834],
          [8.917945191337383, 39.25114743967874],
          [8.917988106681696, 39.25112251508695],
          [8.918041750861883, 39.251089282284184],
          [8.918106123878276, 39.25107266587685],
          [8.918202683402802, 39.25105604946567],
          [8.918288514091453, 39.25104774125852],
          [8.91838507361598, 39.25104774125852],
          [8.918470904304376, 39.25104774125852],
          [8.918535277320744, 39.25105604946567],
          [8.918588921500957, 39.25108097408108],
          [8.918642565681425, 39.25112251508695],
          [8.918664023353454, 39.25116405606834],
          [8.918706938697767, 39.251222213400844],
          [8.918728396369822, 39.251313603397456],
          [8.918728396369822, 39.25137176060591],
          [8.918728396369822, 39.25143822592807],
          [8.918717667533667, 39.25150469118719],
          [8.918685481025458, 39.25155454009018],
          [8.918664023353454, 39.25158777267247],
          [8.918642565681425, 39.25162100523898],
          [8.918621108009141, 39.2516376215164],
          [8.918599650337061, 39.251645929653606],
          [8.918578192665057, 39.25165423778985],
          [8.918546006156873, 39.2516708540593],
          [8.918524548484589, 39.251679162192616],
          [8.91848163314048, 39.25168747032488],
          [8.918449446632348, 39.25169577845614],
          [8.918406531288035, 39.25170408658655],
          [8.918352887107796, 39.25170408658655],
          [8.918309971763483, 39.25170408658655],
          [8.91826705641917, 39.25170408658655],
          [8.918224141075086, 39.25170408658655],
          [8.918202683402802, 39.25170408658655],
          [8.918191954566877, 39.25170408658655],
          [8.918181225730773, 39.25170408658655],
          [8.918170496894644, 39.25170408658655],
        ],
        author: 11,
      },
      {
        id: 5,
        path: [
          [8.922848269440939, 39.25176847457188],
          [8.922837540605038, 39.25176847457188],
          [8.922740981080512, 39.25176847457188],
          [8.922622963883702, 39.25173524207524],
          [8.922483489015093, 39.25166877703463],
          [8.9223225564742, 39.251569079355626],
          [8.922183081605334, 39.251452765217635],
          [8.922011420228312, 39.251253369103644],
          [8.921957776048073, 39.25116197902866],
          [8.921882674195576, 39.25100412316385],
          [8.921850487687419, 39.2508296504789],
          [8.921850487687419, 39.25072164336115],
          [8.921871945359676, 39.25060532781751],
          [8.921989962556257, 39.250472395531425],
          [8.922183081605334, 39.25037269615151],
          [8.922644421555987, 39.25027299662982],
          [8.922826811768909, 39.25025638002903],
          [8.923384711244086, 39.25026468832997],
          [8.92369584748972, 39.25035607957436],
          [8.923974797227194, 39.25049732035428],
          [8.924125000932188, 39.25060532781751],
          [8.92423228929264, 39.250696718618066],
          [8.924307391145136, 39.25078810929952],
          [8.92435030648945, 39.250837958711905],
          [8.924371764161478, 39.25087119163377],
          [8.924382492997633, 39.2509210409871],
          [8.924382492997633, 39.25099581495064],
          [8.92435030648945, 39.25110382164608],
          [8.92423228929264, 39.2512782936489],
          [8.924082085587875, 39.251485997848214],
          [8.923899695374951, 39.25166046890014],
          [8.923706576325875, 39.25176847457188],
          [8.923502728440667, 39.25183493951797],
          [8.923255965211377, 39.251851555744594],
          [8.92308430383433, 39.251851555744594],
          [8.922977015473649, 39.251851555744594],
        ],
        type: "drawing",
      },
      {
        path: [[8.916196391084084, 39.250680102117705]],
        id: 6,
        type: "drawing",
      },
      {
        id: 7,
        path: [
          [8.922065064408754, 39.2530312977747],
          [8.923588559129065, 39.25383716648355],
          [8.925787970521338, 39.25210910675465],
          [8.925058409669367, 39.25071333511448],
          [8.922065064408754, 39.2530312977747],
        ],
        done: true,
        type: "measurement",
      },
    ],
  },
];
