import { createActions, handleActions } from "redux-actions";
import { createSelector } from "reselect";
import apiClient from "utility/apiClient";

/* -- actions -- */
export const fetchProjects = () => async dispatch => {
  dispatch(fetchProjectsRequest());

  try {
    const data = await apiClient({ cache: false })({
      action: "get_progetti",
    });

    dispatch(fetchProjectsSucceeded(data));
  } catch (err) {
    dispatch(fetchProjectsFailed(err));
  }
};

export const {
  fetchProjectsRequest,
  fetchProjectsSucceeded,
  fetchProjectsFailed,
} = createActions({
  FETCH_PROJECTS_REQUEST: projectId => ({ projectId }),
  FETCH_PROJECTS_SUCCEEDED: projects => ({ projects }),
  FETCH_PROJECTS_FAILED: err => ({ err }),
});

export const { setActiveProject } = createActions({
  SET_ACTIVE_PROJECT: projectId => ({ projectId }),
});

/* -- reducers --  */
export const reducer = handleActions(
  {
    [fetchProjectsSucceeded]: (state, { payload: { projects } }) => {
      const sortedProjs = projects.sort((x, y) => (x.id > y.id ? 1 : -1));
      return {
        projectList: sortedProjs,
        activeProject: sortedProjs ? sortedProjs[0].id : null,
      };
    },
    [setActiveProject]: (state, { payload: { projectId } }) => {
      return {
        ...state,
        activeProject: projectId,
      };
    },
  },
  {
    projectList: [],
    activeProject: null,
  }
);

/* -- selectors -- */
export const projectSelector = state => state.projects;

export const projectsSelector = createSelector(
  projectSelector,
  projects => projects.projectList
);

export const activeProjectSelector = createSelector(
  projectSelector,
  projects => projects.activeProject
);

export const projectsSelectorById = id =>
  createSelector(
    projectsSelector,
    projects => projects.find(project => project.id === id)
  );
