import React, { useState, useContext, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { useTranslation } from "react-i18next";

import apiClient from "utility/apiClient";
import * as qs from "query-string";

import logo from "../logo.svg";
import {
  Field,
  Container,
  Wrapper,
  SubmitBtn,
  Logo,
  ErrorMessage,
  Lol,
} from "./style";

import { setUser, userSelector } from "store/user";
import { toastContext } from "components/Toast/Toast";
import Sidekick from "components/Sidekick";
import Modal from "components/Modal";
import AnimatedCheck from "components/AnimatedCheck";
import "styled-components/macro";

function LoginForm(props) {
  const { t } = useTranslation();

  const [values, setValues] = useState({
    email: null,
    pw: null,
  });
  const [showRequireNewPassword, setShowRequireNewPassword] = useState(false);

  const emailInput = useRef();

  const [error, setError] = useState(null);
  const { sendToast } = useContext(toastContext);

  async function handleSubmit(e) {
    const { setUser } = props;

    e.preventDefault();

    try {
      const user = await apiClient({})({ data: values, action: "login" });

      setUser({ ...user, clientId: process.env.REACT_APP_CLIENT_ID });

      sendToast({
        type: "SUCCESS",
        message: t(`Welcome back {{name}}`, { name: user.nome }),
      });
    } catch (err) {
      setError(err.message);
    }
  }

  function handleChange(name, value) {
    setValues({
      ...values,
      [name]: value,
    });

    setError(null);
  }

  useEffect(() => {
    emailInput.current.focus();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Field
          label="Email"
          name="email"
          value={values["email"]}
          onChange={e => handleChange("email", e.target.value)}
          ref={emailInput}
        />
        <Field
          label="Password"
          name="pw"
          value={values["pw"]}
          type="password"
          onChange={e => handleChange("pw", e.target.value)}
        />

        <Sidekick.Label
          onClick={() => setShowRequireNewPassword(true)}
          hoverUnderline
        >
          {t("login:forgot-password")}
        </Sidekick.Label>

        {error && <ErrorMessage>{error}</ErrorMessage>}

        <SubmitBtn type="submit"> {t("Login")} </SubmitBtn>
      </form>
      {showRequireNewPassword && (
        <ModalRequireNewPassword
          defaultValue={emailInput.current.value}
          handleClick={() => setShowRequireNewPassword(false)}
        />
      )}
    </>
  );
}

function ModalRequireNewPassword(props) {
  const { handleClick, defaultValue = "" } = props;
  const emailInput = useRef();
  const [inputVal, setInputVal] = useState(defaultValue);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  function handleChange(e) {
    setInputVal(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      await apiClient({})({
        action: "send_reset_token",
        data: {
          email: inputVal,
        },
      });

      setIsSubmitted(true);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }

  useEffect(() => {
    emailInput.current.focus();
  }, []);

  const { t } = useTranslation();

  return (
    <Modal onCloseClick={handleClick}>
      <>
        {!isSubmitted ? (
          <>
            <Sidekick.Title>{t("login:reset-password")}</Sidekick.Title>
            <Sidekick.Text>{t("login:reset-password-intro")}</Sidekick.Text>
            <form onSubmit={handleSubmit}>
              <Field
                label="Email"
                name="email"
                type="email"
                value={inputVal}
                onChange={handleChange}
                ref={emailInput}
                required
              />

              {error && <ErrorMessage>{error}</ErrorMessage>}

              <div
                css={`
                  display: flex;
                  flex-flow: row nowrap;
                  justify-content: space-between;
                `}
              >
                <div
                  css={`
                    margin-top: 1rem;
                    display: flex;
                    justify-content: left;
                    flex-basis: 50%;
                    align-self: center;
                  `}
                >
                  <Sidekick.Label onClick={handleClick} hoverUnderline>
                    {t("cancel")}
                  </Sidekick.Label>
                </div>
                <div
                  css={`
                    flex-basis: 50%;
                  `}
                >
                  <SubmitBtn type="submit">{t("send")}</SubmitBtn>
                </div>
              </div>
            </form>
          </>
        ) : (
          <>
            <AnimatedCheck scale={0.35} />
            <Sidekick.Text alignCenter>
              {t("login:reset-request-success")}
            </Sidekick.Text>
            <Sidekick.Label
              onClick={handleClick}
              hoverUnderline
              css={`
                margin-top: 1rem;
                text-align: center;
              `}
            >
              {t("Chiudi")}
            </Sidekick.Label>
          </>
        )}
      </>
    </Modal>
  );
}

function MagicForm(props) {
  const [values, setValues] = useState({
    email: null,
    pw: null,
  });

  const [error, setError] = useState(null);

  function handleChange(name, value) {
    setValues({
      ...values,
      [name]: value,
    });

    setError(null);
  }

  async function handleSubmit(e) {
    const { setUser } = props;

    e.preventDefault();

    try {
      const data = await apiClient({})({
        data: values,
        action: "crea_onetime_token",
      });

      setUser({ ...data, clientId: process.env.REACT_APP_CLIENT_ID });
    } catch (err) {
      setError(err.message);
    }
  }

  const emailInput = useRef();

  useEffect(() => {
    emailInput.current.focus();
  }, []);

  return (
    <>
      <p>
        Inserisci l'email con la quale sei stato invitato e ti invieremo un link
        di accesso.
      </p>
      <form onSubmit={handleSubmit}>
        <Field
          label="Email"
          name="email"
          type="email"
          value={values["email"]}
          onChange={e => handleChange("email", e.target.value)}
          ref={emailInput}
        />

        {error && <ErrorMessage>{error}</ErrorMessage>}

        <SubmitBtn type="submit">Invia Link Per Accedere</SubmitBtn>
      </form>
    </>
  );
}

function Login(props) {
  const [magic, setMagic] = useState(false);

  if (props.userLogged) {
    const { to } = qs.parse(window.location.search);

    return <Redirect to={to ? window.decodeURIComponent(to) : "/"} />;
  }

  return (
    <Container>
      <Wrapper>
        <Logo src={logo} />
        {magic ? <MagicForm /> : <LoginForm {...props} />}

        {/* <Lol onClick={() => setMagic(!magic)}>
          {magic ? "Accedi con username e password" : "Accedi con link magico"}
        </Lol> */}
      </Wrapper>
    </Container>
  );
}

const mapStateToProps = state => ({
  userLogged: userSelector(state),
});

export default connect(
  mapStateToProps,
  { setUser }
)(Login);
