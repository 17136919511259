export default [
  { value: 0xecf0f1, label: "" },
  { value: 0xf1c40f, label: "" },
  { value: 0xe67e22, label: "" },
  { value: 0xc0392b, label: "" },
  { value: 0x9b59b6, label: "" },
  { value: 0xe569ff, label: "" },
  { value: 0x3498db, label: "" },
  { value: 0x2ecc71, label: "" },
];
