export default function getBoundingBox(path) {
  let highestX = path[0][0];
  let highestY = path[0][1];
  let lowestX = path[0][0];
  let lowestY = path[0][1];

  for (let l = path.length - 1; l > 0; l--) {
    const [x, y] = path[l];

    highestX = Math.max(x, highestX);
    lowestX = Math.min(x, lowestX);
    highestY = Math.max(y, highestY);
    lowestY = Math.min(y, lowestY);
  }

  return [
    [lowestX, highestY],
    [highestX, highestY],
    [highestX, lowestY],
    [lowestX, lowestY],
  ];
}
