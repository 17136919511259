import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import createSagaMiddleware from "redux-saga";
import { call } from "redux-saga/effects";

import thunk from "redux-thunk";

import { createLogger } from "redux-logger";

import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware, push } from "connected-react-router";

import * as user from "./user";
import * as markers from "./markers";
import * as meta from "./meta";
import * as documents from "./documents";
import * as rilievi from "./rilievi";
import * as measurements from "./measurements";
import * as markerData from "./markerData";
import * as settings from "./settings";
import * as clouds from "./clouds";
import * as views from "./views";
import * as objects from "./objects";
import * as notifications from "./notifications";
import * as projects from "./projects";
import * as users from "./users";
import * as trashbin from "./trashbin";
import * as permissionsWindow from "./permissions";

import * as layers from "./layers";

import saga from "./sagas";

const defaultState = {};

const createRootReducer = history => (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return combineReducers({
    router: connectRouter(history),

    user: user.reducer,
    meta: meta.reducer,
    documents: documents.reducer,
    rilievi: rilievi.reducer,
    markers: markers.reducer,
    measurements: measurements.reducer,
    markerData: markerData.reducer,
    clouds: clouds.reducer,
    settings: settings.reducer,
    layers: layers.reducer,
    views: views.reducer,
    objects: objects.reducer,
    notifications: notifications.reducer,
    projects: projects.reducer,
    users: users.reducer,
    trashbin: trashbin.reducer,
    permissionsWindow: permissionsWindow.reducer,
  })(state, action);
};

function* rootSaga() {
  yield call(saga);
}

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const composeEnhancers = composeWithDevTools({
  name: "primis-store",
  actionCreators: {
    setUser: user.setUser,
    ...meta,
    ...markerData,
    push,
  },
  features: {
    persist: true,
    export: false,
    pause: false,
    test: false,
    dispatch: true,
  },
});

const sagaMiddleware = createSagaMiddleware();

// eslint-disable-next-line
const logger = createLogger({
  collapsed: true,
});

export const history = createBrowserHistory();

export const store = createStore(
  persistReducer(persistConfig, createRootReducer(history)),
  defaultState,
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      thunk.withExtraArgument(x => console.log(x)),
      sagaMiddleware
    )
  )
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
