import { createActions, handleActions } from "redux-actions";
import { createSelector } from "reselect";

import apiClient from "utility/apiClient";
import produce from "immer";

const api = {
  fetch: ({ rilievoId }) =>
    apiClient()({
      action: "get_documenti",
      data: {
        id_rilievo: rilievoId,
      },
    }),
};

/** -- actions -- **/
export const { openIframeModal, closeIframeModal } = createActions({
  OPEN_IFRAME_MODAL: (src, title) => ({ src, title }),
  CLOSE_IFRAME_MODAL: null,
});

export const {
  fetchDocumentsRequest,
  fetchDocumentsSucceeded,
  fetchDocumentsFailed,
} = createActions(
  "FETCH_DOCUMENTS_REQUEST",
  "FETCH_DOCUMENTS_SUCCEEDED",
  "FETCH_DOCUMENTS_FAILED"
);

export const fetchDocuments = ({ rilievoId }) => async dispatch => {
  dispatch(fetchDocumentsRequest({ rilievoId }));

  try {
    const documents = await api.fetch({ rilievoId });
    dispatch(fetchDocumentsSucceeded({ documents, rilievoId }));
  } catch (err) {
    dispatch(fetchDocumentsFailed(err));
  }
};

/** -- reducer -- **/
export const reducer = handleActions(
  {
    [openIframeModal]: (state, action) =>
      produce(state, draft => {
        const { payload } = action;
        const { src, title } = payload;
        if (src && title) {
          draft.iframe.src = src;
          draft.iframe.title = title;
          draft.iframe.visible = true;
        }
      }),
    [closeIframeModal]: (state, action) =>
      produce(state, draft => {
        draft.iframe.src = null;
        draft.iframe.visible = false;
      }),
    [fetchDocumentsRequest]: (state, action) =>
      produce(state, draft => {
        draft.loading = true;
      }),
    [fetchDocumentsSucceeded]: (state, action) =>
      produce(state, draft => {
        draft.loading = false;
        draft.data = action.payload.documents;
      }),
  },
  {
    loading: false,
    data: [],
    iframe: {
      src: null,
      title: null,
      visible: false,
    },
  }
);

/* -- selectors -- */
export const documentsSelector = state => state.documents;

export const documentsDataSelector = createSelector(
  documentsSelector,
  documents => documents.data
);

export const documentsIframeSelector = createSelector(
  documentsSelector,
  documents => documents.iframe
);

export const documentsLoadingSelector = createSelector(
  documentsSelector,
  documents => documents.loading
);
