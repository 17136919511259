import { createAction, handleActions } from "redux-actions";
import { createSelector } from "reselect";

import { editItem } from "../meta";
import { fetchMarkerData, markerDataSelectorByMarkerId } from "../markerData";

/* -- action creators -- */
export const setMarkerHover = createAction("SET_MARKER_HOVER");
export const unsetMarkerHover = createAction("UNSET_MARKER_HOVER");
export const setMarkerEdited = createAction("SET_MARKER_EDITED");

export const editMarker = (id, type) => async (dispatch, getState) => {
  await dispatch(fetchMarkerData(id));

  if (type == 9) {
    const markerData = markerDataSelectorByMarkerId(id)(getState());
    if (markerData && markerData.length > 0) {
      const { valore } = markerData[0];
      window.open(`https://primis-sfera.netlify.com/pano/${valore}`, "_blank");
    }
  } else {
    dispatch(
      editItem({
        id,
        type: "marker",
      })
    );
    dispatch(setMarkerEdited({ markerId: id }));
    dispatch(unsetMarkerHover());
  }
};

/* -- reducer -- */
export const reducer = handleActions(
  {
    [setMarkerHover]: (state, action) => {
      const { markerId } = action.payload;

      return {
        ...state,
        hoveredMarker: markerId || null,
      };
    },
    [unsetMarkerHover]: (state, action) => {
      return {
        ...state,
        hoveredMarker: null,
      };
    },
    [setMarkerEdited]: (state, action) => {
      const { markerId } = action.payload;

      return {
        ...state,
        editedMarker: markerId || null,
      };
    },
  },
  {
    hoveredMarker: null,
    editedMarker: null,
  }
);

/* -- selectors -- */
const metaSelector = state => state.meta;

export const _hoveredMarkerSelector = createSelector(
  metaSelector,
  state => state.markers.hoveredMarker
);

export const hoveredMarkerSelector = markerId =>
  createSelector(
    _hoveredMarkerSelector,
    hoveredMarker => {
      return hoveredMarker === markerId;
    }
  );

export const editedMarkerSelector = markerId =>
  createSelector(
    metaSelector,
    state => state.markers.editedMarker === markerId
  );
