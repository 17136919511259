import { createActions, handleActions } from "redux-actions";

const Potree = window.Potree;

/** -- actions -- **/
export const { updateSetting, mergeSettings } = createActions({
  UPDATE_SETTING: ({ name, value }) => ({ name, value }),
  MERGE_SETTINGS: ({ settings }) => ({ settings }),
});
/** -- reducers -- **/
export const reducer = handleActions(
  {
    [updateSetting]: (state, { payload: { name, value } }) => {
      return {
        ...state,
        [name]: value,
      };
    },
    [mergeSettings]: (state, { payload: { settings } }) => {
      return {
        ...state,
        ...settings,
      };
    },
  },
  {
    pointBudget: 1000 * 1000,
    edl: false,
    edlRadius: 0.1,
    edlStrength: 0.1,
    fov: 60,
    clipTask: Potree && Potree.ClipTask && Potree.ClipTask.HIGHLIGHT,
    useHQ: false,
    resolution: window.devicePixelRatio || 1,
  }
);

/** -- selectors -- **/
export const settingsSelector = state => state.settings;
