import React from "react";
import styled, { css } from "styled-components";

import logo from "../logo.svg";

function PageLoading(props) {
  return (
    <Container fixed={props.fixed}>
      <Logo src={logo} />
    </Container>
  );
}

const Logo = styled.img`
  margin-bottom: 1rem;
  width: 24px;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background-color: #111;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg path {
    fill: ${props => props.theme.high};
  }

  ${props =>
    props.fixed &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      z-index: 1000;
    `}
`;

export default PageLoading;
