import { createSelector } from "reselect";
import { createActions, handleActions } from "redux-actions";

import apiClient from "utility/apiClient";
import produce from "immer";

const api = {
  fetch: () => {
    return apiClient({ cache: false })({
      action: "get_aggiornamenti",
    }).then(data => {
      /**
          tipo

          0= insert
          1=update
          2=delete
          3=citazione messaggio direct
          4=permessi
         */

      return data.map(x => {
        const {
          id,
          messaggio,
          id_rilievo,
          id_marker,
          id_misura,
          id_dato,
          id_utente,
          d_i,
        } = x;

        const item = {
          ...x,
          id,
          date: d_i,
          message: messaggio,
          userId: id_utente,
          rilievoId: id_rilievo,
        };

        if (id_misura) {
          item.type = "MEASUREMENT";
          item.itemId = id_misura;
        }

        if (id_marker) {
          item.type = "MARKER";
          item.itemId = id_marker;
        }

        if (id_dato) {
          item.type = "MARKERDATA";
          item.itemId = `${id_marker}/${id_dato}`;
        }

        return item;
      });
    });
  },
};

export const {
  fetchNotificationsRequest,
  fetchNotificationsSucceeded,
  fetchNotificationsFailed,
} = createActions(
  "FETCH_NOTIFICATIONS_REQUEST",
  "FETCH_NOTIFICATIONS_SUCCEEDED",
  "FETCH_NOTIFICATIONS_FAILED"
);

export const fetchNotifications = () => async dispatch => {
  dispatch(fetchNotificationsRequest());

  try {
    const notifications = await api.fetch();
    console.log(notifications);
    dispatch(fetchNotificationsSucceeded({ notifications }));
  } catch (err) {
    dispatch(fetchNotificationsFailed(err));
  }
};
export const reducer = handleActions(
  {
    [fetchNotificationsSucceeded]: (state, action) =>
      produce(state, draft => {
        const { notifications } = action.payload;
        return notifications;
      }),
  },
  []
);

export const notificationsSelector = state => state.notifications;
export const notificationsSelectorByRilievoId = rilievoId =>
  createSelector(
    notificationsSelector,
    notifications =>
      notifications.filter(
        notification => notification && notification.rilievoId === rilievoId
      )
  );

export const notificationsSelectorByPublic = createSelector(
  notificationsSelector,
  notifications =>
    notifications.filter(notification => notification.pubblico !== "0")
);
