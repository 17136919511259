import { createActions, handleActions } from "redux-actions";
import debounce from "lodash.debounce";
import produce from "immer";
import { createSelector } from "reselect";

import {
  actionSidebarSelector,
  editItem,
  stopEditing,
  changeAction,
} from "../meta";

import { api, DEFAULT_MARKER } from "./lib";

/* -- actions -- */
export const {
  fetchMarkersRequest,
  fetchMarkersSucceeded,
  fetchMarkersFailed,
} = createActions(
  "FETCH_MARKERS_REQUEST",
  "FETCH_MARKERS_SUCCEEDED",
  "FETCH_MARKERS_FAILED"
);

export const fetchMarkers = ({ rilievoId }) => async dispatch => {
  dispatch(fetchMarkersRequest({ rilievoId }));

  try {
    const markers = await api.fetch({ rilievoId });
    dispatch(fetchMarkersSucceeded({ markers, rilievoId }));
  } catch (err) {
    console.log(err);
    dispatch(fetchMarkersFailed(err));
  }
};

export const createMarker = marker => async dispatch => {
  const tempId = new Date().getTime();

  dispatch(createMarkerRequest({ ...marker, tempId }));
  dispatch(changeAction(false));

  try {
    const id = await api.set(marker);
    dispatch(createMarkerSucceeded({ ...marker, id, tempId }));
    dispatch(editItem({ id, type: "marker" }));
  } catch (err) {
    console.log(err);
    dispatch(createMarkerFailed(err));
  }
};

export const {
  createMarkerRequest,
  createMarkerSucceeded,
  createMarkerFailed,
} = createActions(
  "CREATE_MARKER_REQUEST",
  "CREATE_MARKER_SUCCEEDED",
  "CREATE_MARKER_FAILED"
);

export const setMarkerVisibility = ({ id, visible }) => dispatch =>
  dispatch(updateMarkerRequest({ id, name: "visible", value: visible }));

export const { deleteMarkerSucceeded, deleteMarkerFailed } = createActions(
  "DELETE_MARKER_SUCCEEDED",
  "DELETE_MARKER_FAILED"
);

export const deleteMarker = ({ id }) => async (dispatch, getState) => {
  const actionSidebar = actionSidebarSelector(getState());

  // close action sidebar if we are deleting a marker that is currently being edited
  if (
    actionSidebar &&
    actionSidebar.type === "marker" &&
    actionSidebar.id === id
  ) {
    dispatch(stopEditing());
  }

  dispatch(updateMarkerRequest({ id, name: "deleting", value: true }));

  try {
    await api.delete({ id });
    dispatch(deleteMarkerSucceeded({ id }));
  } catch (err) {
    console.error(err);
    dispatch(deleteMarkerFailed({ id, err }));
    dispatch(updateMarkerRequest({ id, name: "deleting", value: false }));
  }
};

export const {
  updateMarkerRequest,
  updateMarkerSucceeded,
  updateMarkerFailed,
  mergeMarkersSettings,
  updateTransformedMarker,
} = createActions({
  UPDATE_MARKER_REQUEST: undefined,
  UPDATE_MARKER_SUCCEEDED: undefined,
  UPDATE_MARKER_FAILED: undefined,
  MERGE_MARKERS_SETTINGS: undefined,
  UPDATE_TRANSFORMED_MARKER: marker => ({ marker }),
});

export const {
  toggleAllMarkersVisibility,
  enableMarkersVisibility,
  disableMarkersVisibility,
} = createActions({
  TOGGLE_ALL_MARKERS_VISIBILITY: undefined,
  ENABLE_MARKERS_VISIBILITY: undefined,
  DISABLE_MARKERS_VISIBILITY: undefined,
});

const update = debounce(async function update(dispatch, getState, id) {
  const marker = markerSelectorById(id)(getState());

  try {
    await api.update({ id, marker });
    dispatch(updateMarkerSucceeded({ id }));
  } catch (err) {
    console.log(err);
    dispatch(updateMarkerFailed({ id, err }));
  }
}, 1000);

export const updateMarker = ({ id, name, value }) => async (
  dispatch,
  getState
) => {
  dispatch(updateMarkerRequest({ id, name, value }));
  update(dispatch, getState, id);
};

// permissions interface
export const setMarkerPermissions = ({ id, permission }) => async dispatch => {
  try {
    await api.setPermissions({ id, permission });
  } catch (err) {
    console.error(err);
  }
};

/* -- reducers --  */
export const reducer = handleActions(
  {
    [fetchMarkersSucceeded]: (state, { payload: { markers } }) =>
      produce(state, draft => markers),
    [createMarkerRequest]: (state, action) =>
      produce(state, draft => {
        draft.push(markerReducer(undefined, action));
      }),
    [deleteMarkerSucceeded]: (state, { payload: { id } }) =>
      produce(state, draft => {
        // eslint-disable-next-line eqeqeq
        return draft.filter(marker => marker.id != id);
      }),
    [mergeMarkersSettings]: (state, { payload: { settings } }) =>
      produce(state, draft => {
        draft.forEach((marker, i) => {
          let mergedMarker = settings.find(
            // eslint-disable-next-line eqeqeq
            mergedMarker => mergedMarker.id == marker.id
          );

          if (typeof mergedMarker !== "undefined") {
            draft[i] = {
              ...marker,
              ...mergedMarker,
            };
          }
        });
      }),
    [createMarkerSucceeded]: (state, action) =>
      produce(state, draft => {
        const { tempId } = action.payload;
        draft.forEach((x, i) => {
          if (x.tempId === tempId) {
            const newMarker = markerReducer(x, action);
            draft[i] = newMarker;
            return;
          }
        });
      }),
    [updateMarkerRequest]: (state, action) =>
      produce(state, draft => {
        draft.forEach((item, i) => {
          if (item.id === action.payload.id) {
            draft[i] = markerReducer(item, action);
          }
        });
      }),
    [toggleAllMarkersVisibility]: (state, action) => {
      let ignoreIds = [];
      if (action.payload) {
        ignoreIds = action.payload.ignoreIds;
      }
      return produce(state, draft => {
        draft.forEach((item, i) => {
          if (!ignoreIds.find(id => id === item.id)) {
            draft[i] = { ...item, visible: !item.visible };
          }
        });
      });
    },
    [enableMarkersVisibility]: (state, action) => {
      let ignoreIds = [];
      if (action.payload) {
        ignoreIds = action.payload.ignoreIds;
      }
      return produce(state, draft => {
        draft.forEach((item, i) => {
          if (!ignoreIds.find(id => id === item.id)) {
            draft[i] = { ...item, visible: true };
          }
        });
      });
    },
    [disableMarkersVisibility]: (state, action) => {
      let ignoreIds = [];
      if (action.payload) {
        ignoreIds = action.payload.ignoreIds;
      }
      return produce(state, draft => {
        draft.forEach((item, i) => {
          if (!ignoreIds.find(id => id === item.id)) {
            draft[i] = { ...item, visible: false };
          }
        });
      });
    },
    [updateTransformedMarker]: (state, action) => {
      const { payload } = action;
      const { marker } = payload;
      return produce(state, draft => {
        draft.forEach((item, i) => {
          if (item.id === marker.id) {
            draft[i] = marker;
          }
        });
      });
    },
  },
  []
);

const markerReducer = handleActions(
  {
    [createMarkerRequest]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        temp: true,
      };
    },
    [createMarkerSucceeded]: (state, { payload }) =>
      produce(state, draft => {
        draft = {
          ...state,
          ...payload,
        };

        delete draft.temp;
        delete draft.tempId;

        return draft;
      }),
    [updateMarkerRequest]: (state, { payload: { name, value } }) =>
      produce(state, draft => {
        draft[name] = value;
      }),
  },
  DEFAULT_MARKER
);

/* -- selectors -- */
export const markersSelector = state => state.markers;

export const markers3DSelector = createSelector(
  markersSelector,
  markers => markers.filter(marker => marker.markerObjType !== 0)
);

export const markers2DSelector = createSelector(
  markersSelector,
  markers => markers.filter(marker => marker.markerObjType === 0)
);

export const markersSelectorByRilievoId = rilievoId =>
  createSelector(
    markersSelector,
    markers => markers.filter(marker => marker)
  );

export const markerSelectorById = id =>
  createSelector(
    markersSelector,
    markers => markers.find(marker => marker.id === id)
  );

export const markerBeingHoveredSelector = createSelector(
  markersSelector,
  markers => markers.find(marker => marker.hover)
);

export const getMarkerBeingEdited = createSelector(
  markersSelector,
  actionSidebarSelector,
  (state, actionSidebar) => {
    if (!actionSidebar) return null;

    return state.find(x => x.id === actionSidebar.id);
  }
);

export const marketCurrentlyBeingEdited = markerId =>
  createSelector(
    getMarkerBeingEdited,
    markerBeingEdited => {
      return markerBeingEdited && markerBeingEdited.id === markerId;
    }
  );
