import React from "react";
import styled from "styled-components";
import { transparentize } from "polished";

export const Field = React.forwardRef((props, ref) => {
  const {
    label,
    value,
    type,
    name,
    onChange,
    disabled = false,
    required,
  } = props;
  return (
    <Field.Container>
      <Field.Label for={name}>{label}</Field.Label>
      <Field.Input
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        ref={ref}
        disabled={disabled}
        required={required}
      />
    </Field.Container>
  );
});

Field.Container = styled.div`
  margin-bottom: 1rem;
`;

Field.Label = styled.label`
  font-size: 10px;
  display: block;
  text-transform: uppercase;
  color: #eee;
  margin-bottom: 10px;
`;

Field.Input = styled.input`
  font-size: 1rem;
  height: 3rem;
  background-color: #000;
  border-radius: 3px;

  width: 100%;
  padding: 0 1rem;

  box-shadow: 0 0 0 5px ${props => transparentize(1, props.theme.high)};
  transition: all 0.3s ease;

  color: white;

  -webkit-appearance: none;

  &:focus {
    box-shadow: 0 0 0 2px ${props => props.theme.high};
  }

  border: 1px solid #444;
  outline: none;

  &:disabled {
    background-color: transparent;
    border-radius: 0;
    border-color: transparent;
    border-bottom-color: #232223;
    padding-left: 0;
    color: #fff;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;

  p {
    line-height: 1.6;
    margin-bottom: 2rem;
  }
`;

export const Wrapper = styled.div`
  flex: 400px 0 0;
  margin: ${props => (props.noMargin ? "0" : "5rem")};
`;

export const SubmitBtn = styled.button`
  font-size: 12px;
  color: white;
  background-color: ${props => props.theme.high};
  border: none;
  padding: 1rem 2rem;
  width: 100%;
  border-radius: 3px;
  margin-top: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: rgba(14, 21, 47, 0.3) 0px 8px 30px;

  border-radius: 8px;

  letter-spacing: 1px;

  &:hover {
    background-color: #068dc7;
    cursor: pointer;
  }
`;

export const Logo = styled.img`
  width: 32px;
  margin-bottom: 8rem;
`;

export const ErrorMessage = styled.div`
  padding: 0.5rem;
  font-size: 11px;
  background-color: ${props => props.theme.red};
  color: white;

  margin-top: 0.5rem;
`;

export const Lol = styled.div`
  font-size: 14px;

  margin-top: 1rem;
  display: block;

  text-decoration: underline !important;
  cursor: pointer;
`;
