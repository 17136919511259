import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import { ConnectedRouter } from "connected-react-router";

import * as Sentry from "@sentry/browser";

import "rc-tooltip/assets/bootstrap.css";

import Login from "pages/Login";
import ResetPassword from "pages/ResetPassword";

import Root from "components/Root";
import LoggedRoute from "components/LoggedRoute";
import PageLoading from "components/PageLoading";

import { store, persistor, history } from "./store";

import DatGuiProvider from "components/DatGuiProvider";
import { ToastProvider } from "components/Toast/Toast";
import theme from "./theme";

import Permissions from "modules/Permissions";
import DocumentsIframe from "modules/DocumentsIframe";
import { fetchProjects, activeProjectSelector } from "store/projects";

const Survey = React.lazy(() => import("modules/Survey"));
const PhotoInspection = React.lazy(() => import("modules/PhotoInspection"));

const UnsupportedBrowser = React.lazy(() =>
  import("components/UnsupportedBrowser")
);
const MapView = React.lazy(() => import("components/MapView"));
const Converter = React.lazy(() => import("pages/Converter"));
const MagicLogin = React.lazy(() => import("pages/magic-login"));
const Settings = React.lazy(() => import("pages/settings"));
const SurveyRouteHelper = React.lazy(() => import("pages/survey-route-helper"));

function LoggedInApp() {
  React.useEffect(() => {
    Sentry.init({
      dsn: "https://d031633931b045afa2f8d1219bc2a53f@sentry.io/1423237",
    });

    Sentry.configureScope(scope => {
      const { user } = store.getState();

      scope.setUser({
        id: user.userid,
        ...user,
      });
    });
  }, []);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchProjects());
  }, []);

  const activeProject = useSelector(activeProjectSelector);

  if (!activeProject) {
    return <PageLoading />;
  }

  return (
    <Root>
      <Permissions />
      <DocumentsIframe />
      <Switch>
        <Route path="/surveys/:rilievoId" exact component={SurveyRouteHelper} />
        <Route
          path="/projects/:projectId/:rilievoId/:markerId/photoinspection"
          exact
          component={PhotoInspection}
        />
        <Route
          path="/projects/:projectId/:rilievoId/:itemType?/:itemId?"
          exact
          component={Survey}
        />
        <Route path="/projects/:projectId" exact component={MapView} />
        <Route path="/settings" component={Settings} />
        <Route path="/converter" exact component={Converter} />
        <Route
          path="*"
          render={() => <Redirect to={`/projects/${activeProject}`} />}
        />
      </Switch>
    </Root>
  );
}

function App(props) {
  const unsupported = typeof WebGL2RenderingContext === "undefined";

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <ToastProvider>
              <DatGuiProvider>
                <React.Suspense fallback={<PageLoading />}>
                  {unsupported ? (
                    <UnsupportedBrowser />
                  ) : (
                    <Switch>
                      <Route path="/magic-login" exact component={MagicLogin} />
                      <Route path="/login" exact component={Login} />
                      <Route
                        path="/reset-password"
                        exact
                        component={ResetPassword}
                      />
                      <LoggedRoute path="/" component={LoggedInApp} />
                    </Switch>
                  )}
                </React.Suspense>
              </DatGuiProvider>
            </ToastProvider>
          </ThemeProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
