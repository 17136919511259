import { handleActions, combineActions } from "redux-actions";
import produce from "immer";

import * as actions from "./actions";

export const reducer = handleActions(
  {
    [actions.fetchMeasurements]: state =>
      produce(state, draft => {
        draft.loading = true;
      }),
    [actions.fetchMeasurementsSucceeded]: (
      state,
      { payload: { measurements } }
    ) =>
      produce(state, draft => {
        draft.data = measurements;
        draft.loading = false;
      }),
    [actions.createMeasurementRequest]: (state, action) =>
      produce(state, draft => {
        draft.data.push(measurementReducer(undefined, action));
      }),
    [actions.createMeasurementSucceeded]: (state, action) =>
      produce(state, draft => {
        draft.data.push(measurementReducer(undefined, action));
      }),
    [actions.deleteMeasurementSucceeded]: (state, { payload: { id } }) =>
      produce(state, draft => {
        draft.data = draft.data.filter(measurement => measurement.id !== id);
      }),
    [combineActions(
      actions.deleteMeasurementRequest,
      actions.updateMeasurementRequest,
      actions.setMeasurementVisibility
    )]: (state, action) =>
      produce(state, draft => {
        const {
          payload: { id, tempId },
        } = action;

        let index = draft.data.findIndex(
          item => (id && item.id === id) || (tempId && item.tempId === tempId)
        );

        draft.data[index] = measurementReducer(draft.data[index], action);
      }),
    [actions.addMeasurement]: (state, action) =>
      produce(state, draft => {
        const { payload } = action;
        draft.data = draft.data.concat(payload);
      }),
    [actions.mergeMeasurementsSettings]: (state, { payload: { settings } }) =>
      produce(state, draft => {
        draft.data.forEach((measurement, i) => {
          let mergedMeasurement = settings.find(
            m =>
              // eslint-disable-next-line eqeqeq
              m.id == measurement.id
          );

          if (typeof mergedMeasurement !== "undefined") {
            draft.data[i] = {
              ...measurement,
              ...mergedMeasurement,
            };
          }
        });
      }),
    [actions.setExportLinks]: (state, { payload: { DXFUrl, GeoJSONUrl } }) =>
      produce(state, draft => {
        draft.exportLinks = {
          DXFUrl,
          GeoJSONUrl,
        };
      }),
  },
  {
    data: [],
    loading: false,
    exportLinks: {
      DXFUrl: null,
      GeoJSONUrl: null,
    },
  }
);

const measurementReducer = handleActions(
  {
    [actions.createMeasurementRequest]: (
      state,
      { payload: { tempId, ...stuff } }
    ) =>
      produce(state, draft => {
        return { tempId, tipo: stuff.type, ...draft, ...stuff };
      }),
    [actions.createMeasurementSucceeded]: (state, { payload }) =>
      produce(state, draft => ({ ...draft, ...payload })),
    [actions.updateMeasurementRequest]: (state, { payload: { merge } }) =>
      produce(state, draft => {
        return { ...draft, ...merge };
      }),
    [actions.deleteMeasurementRequest]: state =>
      produce(state, draft => {
        draft.deleting = true;
      }),
    [actions.setMeasurementVisibility]: (state, { payload: { visible } }) =>
      produce(state, draft => {
        draft.visible = visible;
      }),
    [actions.mergeMeasurementsSettings]: (state, { payload: { settings } }) =>
      produce(state, draft => {
        draft = {
          ...draft,
          ...settings,
        };
      }),
  },
  {
    points: [],
    position: {},
    rotation: {},
    scale: {},
    clip: false,
    visible: true,
    showLabels: false,
    crossTimeline: false,
    permesso: "2",
  }
);

export default reducer;
