import { combineActions, createActions, handleActions } from "redux-actions";
import { createSelector } from "reselect";
import getBoundingBox from "utility/getBoundingBox";

import initialData from "./__fixtures__/layers";

/** 

  {
    data: [{
      id: 0,
      name: "Layer name",
      objects: [{
        id: 0,
        color: [255, 0, 0],
        path: [[]]
      }]
    }]
  }

**/

const initialState = {
  data: initialData,
};

/** -- actions -- **/
export const { deleteLayer, updateLayer } = createActions({
  DELETE_LAYER: () => ({}),
  UPDATE_LAYER: ({ id, name, value }) => ({ id, name, value }),
});

export const {
  addLayerObject,
  updateLayerObject,
  updateLayerObjects,
  setActiveObject,
  deleteActiveObject,
} = createActions({
  ADD_LAYER_OBJECT: (layerId, object) => ({ layerId, object }),
  UPDATE_LAYER_OBJECT: (layerId, objectId, merge) => ({
    layerId,
    objectId,
    merge,
  }),
  SET_ACTIVE_OBJECT: (layerId, objectId) => ({ layerId, objectId }),
  UPDATE_LAYER_OBJECTS: (layerId, merge) => ({ layerId, merge }),
  DELETE_ACTIVE_OBJECT: layerId => ({ layerId }),
});

/** -- reducers -- **/
export const reducer = handleActions(
  {
    [combineActions(
      updateLayerObject,
      updateLayerObjects,
      addLayerObject,
      setActiveObject,
      deleteActiveObject
    )]: (state, action) => {
      const {
        payload: { layerId },
      } = action;

      return {
        ...state,
        data: state.data.map(
          item => (item.id === layerId ? dataReducer(item, action) : item)
        ),
      };
    },
  },
  initialState
);

export const dataReducer = handleActions(
  {
    [addLayerObject]: (state, action) => {
      const {
        payload: { object },
      } = action;

      return {
        ...state,
        objects: [
          ...state.objects,
          {
            ...objectReducer(undefined, action),
            ...object,
          },
        ],
      };
    },
    [updateLayerObject]: (state, action) => {
      const { objectId } = action.payload;

      return {
        ...state,
        objects: state.objects.map(
          object =>
            object.id === objectId ? objectReducer(object, action) : object
        ),
      };
    },
    [updateLayerObjects]: (state, action) => {
      return {
        ...state,
        objects: state.objects.map(object => objectReducer(object, action)),
      };
    },
    [setActiveObject]: (state, action) => {
      const {
        payload: { objectId },
      } = action;

      return {
        ...state,
        objects: state.objects.map(
          object =>
            object.id === objectId
              ? { ...object, active: true }
              : { ...object, active: false }
        ),
      };
    },
    [deleteActiveObject]: (state, action) => {
      return {
        ...state,
        objects: state.objects.filter(object => !object.active),
      };
    },
  },
  {}
);

export const objectReducer = handleActions(
  {
    [addLayerObject]: state => {
      return { ...state, id: parseInt(Math.random() * 1000) };
    },
    [combineActions(updateLayerObject, updateLayerObjects)]: (
      state,
      { payload: { merge } }
    ) => {
      return {
        ...state,
        ...merge,
      };
    },
  },
  {
    color: [255, 0, 0],
  }
);

/** -- selectors -- **/
export const layersSelector = state => state.layers.data;

export const layerSelectorById = layerId =>
  createSelector(layersSelector, layer =>
    layer.find(layer => layer.id === layerId)
  );

export const layerObjectsSelector = layer => {
  const { objects } = layer;

  return {
    drawings: objects.filter(object => object.type === "drawing"),
    measurements: objects.filter(object => object.type === "measurement"),
    boundingBoxes: objects
      .filter(object => object.active)
      .map(object => getBoundingBox(object.path)),
  };
};
