import { all, call, take, put, select } from "redux-saga/effects";
import * as measurements from "./measurements";
import * as views from "./views";
import { rilievoSelectorById } from "./rilievi";
import { activeRilievoIdSelector } from "./meta";

function* setView(action) {
  yield put(views.showView(action.payload.id));
}

function* viewsLinkingSaga() {
  let setViewFromUrl;
  let fetchViewsSucceded;

  let listen = true;
  while (listen) {
    const res = yield take(["SET_VIEW_FROM_URL", "FETCH_VIEWS_SUCCEEDED"]);
    switch (res.type) {
      case "SET_VIEW_FROM_URL":
        setViewFromUrl = res;
        break;
      case "FETCH_VIEWS_SUCCEEDED":
        fetchViewsSucceded = res;
        break;
      default:
        break;
    }

    if (setViewFromUrl && fetchViewsSucceded) {
      listen = false;
      yield call(setView, setViewFromUrl);
    }
  }
}

function* defaultViewSaga() {
  yield all([take("FETCH_VIEWS_SUCCEEDED"), take("FETCH_CLOUDS_SUCCEEDED")]);

  const activeRilievoId = yield select(activeRilievoIdSelector);
  const rilievo = yield select(rilievoSelectorById(activeRilievoId));

  if (rilievo) {
    const id = rilievo.id_vista;
    yield put(views.showView(id));
  }
}

export default function* rootSaga() {
  yield all([
    call(viewsLinkingSaga),
    call(measurements.saga),
    call(defaultViewSaga),
  ]);
}
