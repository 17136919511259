import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchNotifications,
  notificationsSelectorByPublic,
} from "store/notifications";

import { fetchUsers } from "store/users";

function Root({ children }) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchNotifications());
    dispatch(fetchUsers());

    const timeout = setTimeout(() => {
      dispatch(fetchNotifications());
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  const notifications = useSelector(notificationsSelectorByPublic);

  React.useEffect(() => {
    // @TODO: fix notifications
    // sendToasts(notifications.map({ id: notification.id, message:  }))
  }, [notifications]);

  return children;
}

export default Root;
