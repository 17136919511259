import React from "react";
import styled, { css } from "styled-components";

const Icon = styled(function Icon(props) {
  return <i className={`material-icons ${props.className}`}>{props.icon}</i>;
})`
  animation: ${props => (props.animation === "rotate" ? "rotate" : "none")} 2s
    infinite;

  color: ${props => props.theme[props.color]};

  ${props => {
    switch (props.size) {
      case "medium":
        return css`
          font-size: 16px;
        `;

      case "small":
        return css`
          font-size: 12px;
        `;

      case "smallest":
        return css`
          font-size: 10px;
        `;

      default:
        return null;
    }
  }} @keyframes rotate {
    0% {
      transform: rotate(0);
    }

    20% {
      transform: rotate(180deg);
    }

    40% {
      transform: rotate(180deg);
    }

    60% {
      transform: rotate(0deg);
    }
  }
`;

export default Icon;
