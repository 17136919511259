import React from "react";

import styled from "styled-components";

function Spinner() {
  return (
    <Container>
      <Wrapper>
        <svg
          version="1.1"
          x="0px"
          y="0px"
          width="40px"
          height="40px"
          viewBox="0 0 50 50"
        >
          <path
            fill="#110F12"
            d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
          />
        </svg>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 40px;

  svg rect,
  svg path {
    fill: ${props => props.theme.high};
  }

  animation: rotate 1s linear infinite;

  transform-origin: center;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

export default Spinner;
