import axios from "axios";
import Bluebird, { map } from "bluebird";

import throttle from "lodash.throttle";

import slug from "utility/slugify";

function slugify(text) {
  const split = text.split(".");

  const ext = split.pop();

  return `${slug(split.join("."))}.${ext}`;
}

Bluebird.config({
  cancellation: true,
});

const SERVICE_URL = "https://qay2mp14pd.execute-api.eu-central-1.amazonaws.com";

function sleep(time) {
  return new Promise(resolve => {
    setTimeout(resolve, time);
  });
}

async function retry(fn, numberOfRetries, timeout, increaseBy = 0) {
  try {
    return fn();
  } catch (err) {
    if (numberOfRetries === 0) {
      throw err;
    }
    await sleep(timeout);
    return retry(fn, --numberOfRetries, timeout + increaseBy, increaseBy);
  }
}

class Part {
  constructor({
    id,
    part,
    file,
    onComplete,
    onProgress,
    onError,
    additionalPayload,
  }) {
    this.id = id;
    this.part = part;

    this.file = {
      name: file.name,
      type: file.type,
    };

    this.onComplete = onComplete;
    this.onProgress = onProgress;
    this.onError = onError;

    this.bytesUploaded = 0;
    this.additionalPayload = additionalPayload;
  }

  get progress() {
    return this.bytesUploaded / this.part.size;
  }

  promise = UploadId =>
    new Bluebird(async (resolve, reject, onCancel) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      onCancel(() => {
        source.cancel("Operation canceled by the user.");
        this.bytesUploaded = 0;
      });

      try {
        // get signed url for this part
        const { signedURL } = await axios({
          method: "POST",
          url: `${SERVICE_URL}/dev/multipart/upload-part`,
          data: {
            filename: slugify(this.file.name),
            part: this.id,
            UploadId,
            ...this.additionalPayload,
            token: "x",
          },
        }).then(res => res.data);

        // puts file on bucket using the signed url
        const response = await retry(() =>
          axios.put(signedURL, this.part, {
            cancelToken: source.token,
            headers: { "Content-Type": this.file.type },
            onUploadProgress: x => {
              this.speed = x.loaded / x.timeStamp;

              this.bytesUploaded = x.loaded;
              this.onProgress();
            },
          })
        );

        // stores the etag from the response header, will be used to complete the multipart upload
        const etag = response.headers.etag;

        this.etag = etag.substring(1, etag.length - 1);

        this.onComplete(this);
        resolve();
      } catch (err) {
        this.onError(err);
      }
    });
}

class Upload {
  constructor({
    id,
    file,
    onProgress,
    onComplete,
    onError,
    additionalPayload,
  }) {
    this.id = id;
    this.file = file;

    this.UploadId = null;

    this.parts = [];

    this.onProgress = onProgress;
    this.onComplete = onComplete;
    this.onError = onError;

    this.additionalPayload = additionalPayload;

    const PART_SIZE = 1024 * 1024 * 5;
    const NUM_PARTS = Math.floor(this.file.size / PART_SIZE) + 1;

    for (let i = 1; i < NUM_PARTS + 1; i++) {
      const start = (i - 1) * PART_SIZE;
      const end = i * PART_SIZE;
      const part =
        i < NUM_PARTS ? this.file.slice(start, end) : this.file.slice(start);

      this.parts.push(
        new Part({
          id: i,
          part,
          file: this.file,
          onComplete: this.handlePartUpload,
          onProgress: this.onProgress,
          onError: this.onError,
          additionalPayload,
        })
      );
    }
  }

  get bytesUploaded() {
    return this.parts.reduce((bytes, part) => {
      return bytes + part.bytesUploaded;
    }, 0);
  }

  get progress() {
    return this.bytesUploaded / this.file.size;
  }

  handlePartUpload = () => {
    this.onProgress();

    if (this.progress === 1) {
      this.complete();
    }
  };

  async getUploadId() {
    const { UploadId } = await axios({
      method: "POST",
      url: `${SERVICE_URL}/dev/multipart/start-upload`,
      data: {
        filename: slugify(this.file.name),
        ...this.additionalPayload,
      },
    }).then(res => res.data);

    this.UploadId = UploadId;
  }

  complete = throttle(async () => {
    this.response = await axios({
      method: "POST",
      url: `${SERVICE_URL}/dev/multipart/complete-upload`,
      data: {
        filename: slugify(this.file.name),
        UploadId: this.UploadId,
        parts: this.parts.map(part => ({
          ETag: part.etag,
          PartNumber: part.id,
        })),
        ...this.additionalPayload,
      },
    }).then(res => {
      return res.data;
    });

    this.uri = this.response.Location;

    this.onProgress();
  }, 1000);
}

class GgUploader {
  constructor({ files, onProgress, onComplete, onError, additionalPayload }) {
    this.uploads = files.map(
      (file, i) =>
        new Upload({
          id: i,
          file,
          onProgress: this.handleProgress,
          onError: onError,
          additionalPayload: additionalPayload,
        })
    );

    this.onProgress = onProgress;
    this.onComplete = onComplete;
    this.onError = onError;

    this.started = false;
  }

  get totalBytes() {
    return this.uploads.reduce(
      (totalBytes, upload) => upload.file.size + totalBytes,
      0
    );
  }

  get progress() {
    return this.totalBytesUploaded / this.totalBytes;
  }

  get totalBytesUploaded() {
    return this.uploads.reduce((totalBytesUploaded, upload) => {
      return totalBytesUploaded + upload.bytesUploaded;
    }, 0);
  }

  async start() {
    // resolves all uploadIds
    await map(this.uploads, upload => upload.getUploadId(), {
      concurrency: 10,
    });

    this.allPartsPromises = this.uploads.reduce((allPartsPromises, upload) => {
      const { UploadId } = upload;
      const partsPromises = upload.parts.map(part => () =>
        part.promise(UploadId)
      );
      return [...allPartsPromises, ...partsPromises];
    }, []);

    this.started = true;

    this.resume();
    typeof this.onProgress === "function" &&
      this.onProgress(this.progress, this);
  }

  resume() {
    // do nothing if we didn't even start
    if (!this.started) return;

    this.uploadPromise = map(
      this.allPartsPromises,
      (promise, i) =>
        new Bluebird((resolve, reject, onCancel) => {
          if (promise === null) resolve();

          const pr = promise();

          pr.then(() => {
            this.allPartsPromises[i] = null;
            resolve();
          }).catch(() => {
            reject();
          });

          onCancel(() => {
            pr.cancel();
          });
        }),
      {
        concurrency: 10,
      }
    );
  }

  pause() {
    this.uploadPromise.cancel();
  }

  handleProgress = x => {
    typeof this.onProgress === "function" &&
      this.onProgress(this.progress, this);

    if (
      this.uploads.filter(upload => upload.response).length ===
      this.uploads.length
    ) {
      typeof this.onComplete === "function" && this.onComplete(this.uploads);
    }
  };
}

export default GgUploader;

export const simpleUpload = (files, additionalPayload) =>
  new Promise((resolve, reject) => {
    new GgUploader({
      files,
      onComplete: resolve,
      onError: reject,
      additionalPayload,
    }).start();
  });
