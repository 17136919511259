import { createAction, createActions } from "redux-actions";

import { actionSidebarSelector, stopEditing } from "../meta";

import * as api from "./api";
import { measurementSelectorById } from "./selectors";

export const {
  fetchMeasurements,
  fetchMeasurementsRequest,
  fetchMeasurementsSucceeded,
  fetchMeasurementsFailed,
} = createActions({
  FETCH_MEASUREMENTS: undefined,
  FETCH_MEASUREMENTS_REQUEST: undefined,
  FETCH_MEASUREMENTS_SUCCEEDED: undefined,
  FETCH_MEASUREMENTS_FAILED: undefined,
});

export const {
  createMeasurement,
  createMeasurementRequest,
  createMeasurementSucceeded,
  createMeasurementFailed,
  addMeasurement,
} = createActions({
  CREATE_MEASUREMENT: undefined,
  CREATE_MEASUREMENT_REQUEST: undefined,
  CREATE_MEASUREMENT_SUCCEEDED: measure => ({
    ...measure,
    visible: true,
  }),
  CREATE_MEASUREMENT_FAILED: undefined,
  ADD_MEASUREMENT: undefined,
});

export const {
  setMeasurementVisibility,
  setMeasurementHover,
  mergeMeasurementsSettings,
} = createActions({
  SET_MEASUREMENT_VISIBILITY: undefined,
  SET_MEASUREMENT_HOVER: undefined,
  MERGE_MEASUREMENTS_SETTINGS: undefined,
});

export const {
  deleteMeasurementRequest,
  deleteMeasurementSucceeded,
  deleteMeasurementFailed,
} = createActions({
  DELETE_MEASUREMENT_REQUEST: undefined,
  DELETE_MEASUREMENT_SUCCEEDED: undefined,
  DELETE_MEASUREMENT_FAILED: undefined,
});

export const { showProfileWindow } = createActions({
  SHOW_PROFILE_WINDOW: undefined,
});

export const deleteMeasurement = ({ id, imported }) => async (
  dispatch,
  getState
) => {
  const state = getState();

  const actionSidebar = actionSidebarSelector(state);
  const measurement = measurementSelectorById(id)(state);

  if (actionSidebar) {
    if (id === actionSidebar.id && actionSidebar.type === "measurement") {
      dispatch(stopEditing());
    }
  }

  if (measurement.imported) {
    dispatch(deleteMeasurementSucceeded({ id }));
    return;
  }

  dispatch(deleteMeasurementRequest({ id }));

  try {
    await api.removeMisura(id);

    dispatch(deleteMeasurementSucceeded({ id }));
  } catch (err) {
    console.error(err);
    dispatch(deleteMeasurementFailed({ id, err }));
  }
};

export const {
  updateMeasurement,
  updateMeasurementRequest,
  updateMeasurementSucceeded,
  updateMeasurementFailed,
} = createActions({
  UPDATE_MEASUREMENT: undefined,
  UPDATE_MEASUREMENT_REQUEST: undefined,
  UPDATE_MEASUREMENT_SUCCEEDED: undefined,
  UPDATE_MEASUREMENT_FAILED: undefined,
});

// permissions
export const setMeasurementPermissions = ({
  id,
  permission,
}) => async dispatch => {
  try {
    await api.setPermissions(id, permission);

    updateMeasurement({ id, merge: { permission } });
  } catch (err) {
    console.error(err);
  }
};

// export
export const setExportLinks = createAction("SET_EXPORT_LINKS", undefined);
